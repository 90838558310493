import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  dateFormat,
  dateGreaterOrEqual,
  buildQuery,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import ReactPaginate from 'react-paginate';
import { PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { CSVLink, CSVDownload } from 'react-csv';
import PdfGenerator from '../../components/PdfGenerator';
import moment from 'moment';

const ReceiptPOCReport = () => {
  const [receiptNo, setReceiptNo] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableFilter, setTableFilter] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [from, setFrom] = useState(dateFormat(new Date()));
  const [to, setTo] = useState(dateFormat(new Date()));
  const [pdfData, setPdfData] = useState({
    from: '',
    to: '',
    tableTitles: [],
    tableContents: [],
  });
  const filterColumns: Array<String> = ['MACHINE', 'ITEM_Code', 'ITEM_NAME', 'Quantity', 'UOM', 'Item_Rate', 'Value'];
  const getLocaleNumber = (num) => {
    let currNum = +num;
    return currNum.toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  };
  const filterChange = (searchInput: any, newData = false) => {
    setTableFilter(searchInput);
    let available: any = [];
    if (newData) {
      available = newData;
    } else {
      available = itemData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns, true);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };
  const columns = [
    {
      name: 'MACHINE',
      selector: (row) => `${row.MACHINE}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Item Code',
      selector: (row) => `${row.ITEM_Code}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Item Name',
      selector: (row) => `${row.ITEM_NAME}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Quantity',
      selector: (row) => `${row.Quantity}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'UOM',
      selector: (row) => `${row.UOM}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Item Rate',
      selector: (row) => `${row.Item_Rate}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Value',
      selector: (row) => `${row.Value}`,
      sortable: true,
      maxWidth: '45%',
    },
  ];
  const pdfTableTitles = ['Machine', 'Item Code', 'Item Name', 'Qty', 'UOM', 'Item Rate', 'Val'];
  const getApi = async () => {
    showLoader();
    let res: any = await axios.get(`${baseUri()}reports/machinewise-itemwise?from=${from}&to=${to}`);
    res = (await res.data.data) || [];
    const totContent = {
      Quantity: 0,
      Item_Rate: 0,
      Value: 0,
    };
    let prevMachineName = '';
    let prevTotContent = {
      Value: 0,
    };
    const pdfContent = [];
    res.forEach(
      (row: { MACHINE: any; ITEM_Code: any; ITEM_NAME: any; Quantity: any; UOM: any; Item_Rate: any; Value: any }) => {
        totContent.Quantity += +row.Quantity;
        totContent.Item_Rate += +row.Item_Rate;
        totContent.Value += +row.Value;
        let currMachineName = row.MACHINE;
        if (prevMachineName === row.MACHINE) {
          currMachineName = '';
          prevTotContent.Value += +row.Value;
        } else {
          if (prevMachineName !== '') {
            pdfContent.push([
              '',
              '',
              '',
              '',
              '',
              '',
              (+prevTotContent.Value).toLocaleString('en-IN', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            ]);
          }
          prevMachineName = row.MACHINE;
          prevTotContent.Value = +row.Value;
        }
        pdfContent.push([
          currMachineName,
          row.ITEM_Code,
          row.ITEM_NAME,
          (+row.Quantity).toLocaleString('en-IN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          row.UOM,
          (+row.Item_Rate).toLocaleString('en-IN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          (+row.Value).toLocaleString('en-IN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        ]);
      },
    );
    pdfContent.push([
      '',
      '',
      '',
      '',
      '',
      '',
      (+prevTotContent.Value).toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    ]);
    pdfContent.push([
      ' ',
      ' ',
      ' ',
      (+totContent.Quantity).toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      ' ',
      (+totContent.Item_Rate).toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      (+totContent.Value).toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    ]);
    setPdfData({
      from: moment(from).format('DD/MM/YY'),
      to: moment(to).format('DD/MM/YY'),
      tableContents: pdfContent,
      tableTitles: pdfTableTitles,
    });
    setTableData(res);
    setItemData(res);
    hideLoader();
  };

  return (
    <>
      <SEO title="MachineWise-ItemWise Report" />
      <Card>
        <header>MachineWise-ItemWise Report</header>
        <CardBody>
          <Row>
            <Col breakPoint={{ xs: 12, md: 4 }}>
              <label>
                From Date <span className="red">*</span>
              </label>
              <InputGroup fullWidth size="Small">
                <input
                  type="date"
                  name="from"
                  value={from}
                  placeholder="From Date"
                  onChange={(e) => setFrom(e.target.value)}
                  className="from require"
                />
              </InputGroup>
            </Col>
            <Col breakPoint={{ xs: 12, md: 4 }}>
              <label>
                To Date <span className="red">*</span>
              </label>
              <InputGroup fullWidth size="Small">
                <input
                  type="date"
                  name="to"
                  value={to}
                  placeholder="To Date"
                  onChange={(e) => setTo(e.target.value)}
                  className="to require"
                />
              </InputGroup>
            </Col>
          </Row>
          <div
            style={{
              marginTop: '10px',
            }}
          >
            <Button
              status="Success"
              style={{
                marginRight: '10px',
              }}
              onClick={getApi}
            >
              Submit
            </Button>
          </div>
        </CardBody>
      </Card>
      <Card>
        <header>MachineWise-ItemWise Report</header>
        <CardBody>
          {tableData.length > 0 && (
            <div>
              <CSVLink data={tableData}>Download Excel</CSVLink>
              {'  '}
              <PdfGenerator documentTitle="machinewise-itemwise-report" data={pdfData} />
            </div>
          )}
          <InputGroup fullWidth size="Small">
            <input
              type="text"
              onChange={(e) => filterChange(e.target.value)}
              placeholder="Filter"
              name="tableFilter"
              value={tableFilter}
            />
          </InputGroup>
          <DataTable
            columns={columns}
            data={tableData}
            fixedHeader
            allowOverflow={false}
            overflowY={false}
            overflowX={false}
            wrap={false}
            highlightOnHover={true}
            pagination={true}
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default ReceiptPOCReport;
